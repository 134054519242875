/* eslint-disable no-bitwise */
import { KoddiTheme } from 'koddi-components/ThemeProvider';
import { generateTints } from '../../Chakra.utils';

export const colorConfig = (theme: KoddiTheme): Record<string, any> => ({
    primary: generateTints(theme.primary),
    secondary: generateTints(theme.secondary),
    gray: generateTints(theme.gray),

    primaryColor: theme.primary,
    secondaryColor: theme.secondary,
    grayColor: theme.gray,
    disabledColor: theme.grayDarker,

    grayLight: theme.grayLight,
    grayLighter: theme.grayLighter,
    grayLightest: theme.grayLightest,

    grayDark: theme.grayDark,
    grayDarker: theme.grayDarker,
    grayDarkest: theme.grayDarkest,

    grayCool: theme.coolGray,
    slate: theme.slate,
    cardGray: theme.grayLightest,

    btnPrimary: theme.btnPrimary,
    btnSecondary: theme.btnSecondary,

    appNavBackground: theme.headerBackground,
    // backwards compatible with old header brand values
    avatarBackground: theme?.avatarColor || theme?.headerTextColor,
    avatarText: theme.headerBackground,
    // extend the above for all colors
});

export enum ColorSchemes {
    primary = 'primary',
    secondary = 'secondary',
    gray = 'gray',
    success = 'green',
    error = 'red',
    warning = 'yellow',
}

export enum Colors {
    // client defined colors
    primary = 'primaryColor',
    secondary = 'secondaryColor',
    gray = 'grayColor',
    disabled = 'disabledColor',

    grayLight = 'grayLight',
    grayLighter = 'grayLighter',
    grayLightest = 'grayLightest',

    grayDark = 'grayDark',
    grayDarker = 'grayDarker',
    grayDarkest = 'grayDarkest',

    grayCool = 'grayCool',
    slate = 'slate',
    cardGray = 'grayLightest',

    white = 'white',
    black = 'black',

    btnPrimary = 'btnPrimary',
    btnSecondary = 'btnSecondary',

    appNavBackground = 'appNavBackground',
    avatarBackground = 'avatarBackground',
    avatarText = 'appNavBackground',

    inputIcon = 'gray',

    // system defined colors
    successDark = 'green.800',
    success = 'green.500',
    successLight = 'green.200',

    errorDark = 'red.800',
    error = 'red.500',
    errorLight = 'red.200',

    warningDark = 'orange.700',
    warning = 'orange.400',
    warningLight = 'orange.100',
    warningLighter = 'yellow.500',

    skeleton = 'gray.400',

    // new standards
    pageBackground = '#F7F7F7',
    primaryText = '#131126',
    secondaryText = '#6D82A4',
}

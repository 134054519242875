import React from 'react';
import { createGlobalStyle, css } from 'styled-components';
import { FontValues } from 'koddi-components/global.types';
import { FontSizes } from '../_ChakraComponents/ChakraTheme/configs/Typography.config';

const KoddiGlobalStyles = createGlobalStyle`
    ${({ theme }) => {
        const fontFamily =
            theme.typography.fontFamily === 'sans-serif'
                ? FontValues.default
                : theme.typography.fontFamily.replace(`'Noto Sans'`, `'Inter'`);
        return css`
            /** Reference: https://css-tricks.com/snippets/css/hide-scrollbar-in-edge-ie-1011/ */
            html {
                -ms-overflow-style: -ms-autohiding-scrollbar;
            }
            html,
            body,
            #root {
                width: 100%;
                height: 100%;
            }

            body {
                font-family: ${fontFamily};
                margin: 0px;
                font-size: ${FontSizes.md};
            }

            * {
                box-sizing: border-box;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }

            a {
                text-decoration: none;
                color: ${theme.linkColor};
            }
            p {
                line-height: 1.2rem;
            }
        `;
    }}
`;

export default React.memo(KoddiGlobalStyles);

import React, { ReactNode } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { useChakraTheme } from './useChakraTheme.hooks';
import ThemeProvider, { KoddiTheme } from '../../ThemeProvider';

export const ChakraTheme = ({
    children,
    theme,
}: {
    children: ReactNode;
    theme?: KoddiTheme;
}): JSX.Element => {
    const chakraCustomTheme = useChakraTheme();
    return (
        <ThemeProvider theme={theme}>
            <ChakraProvider theme={chakraCustomTheme}>
                {children}
            </ChakraProvider>
        </ThemeProvider>
    );
};

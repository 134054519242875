import { KoddiTheme } from 'koddi-components/ThemeProvider';
import { Border, BorderRadius } from './Borders.config';
import { Colors } from './Colors.config';
import { ChakraSizes } from './Sizes.config';
import { Spacing } from './Spacing.config';

export const buttonConfig = (theme: KoddiTheme): Record<string, unknown> => ({
    defaultProps: {
        variant: 'primary',
        cursor: 'pointer',
    },
    baseStyle: {
        border: `${Border.Width.Thin} solid transparent`,
        cursor: 'pointer',
        padding: Spacing.SM,
        fontWeight: 300,
        borderRadius: BorderRadius.SM,
    },
    size: {
        sm: {
            fontSize: ChakraSizes.sm,
        },
    },
    variants: {
        wrapper: {
            border: `none`,
            padding: 0,
            px: 0,
            mx: 0,
            borderRadius: 'none',
            height: 'unset',
            width: 'unset',
            minWidth: 'unset',
            display: 'inline-block',
        },
        primary: {
            bg: Colors.btnPrimary,
            color: Colors.white,
            _disabled: {
                bg: Colors.disabled,
            },
            _hover: {
                bg: theme.createStateColor(theme.btnPrimary),
                _disabled: {
                    bg: Colors.disabled,
                },
            },
        },
        secondary: {
            bg: 'none',
            color: Colors.grayDarkest,
            _hover: {
                color: Colors.grayDarkest,
                bg: theme.lighten(theme.grayDarkest, 0.75),
            },
        },
        border: {
            bg: Colors.white,
            color: Colors.btnPrimary,
            fill: Colors.btnPrimary,
            border: `${Border.Width.Thin} solid`,
            borderColor: Colors.btnPrimary,
            _disabled: {
                color: Colors.disabled,
                borderColor: Colors.disabled,
                fill: Colors.disabled,
            },
            _hover: {
                borderColor: theme.createStateColor(theme.btnPrimary),
                color: theme.createStateColor(theme.btnPrimary),
                fill: theme.createStateColor(theme.btnPrimary),
                bg: theme.lighten(theme.btnPrimary, 0.55),
                _disabled: {
                    bg: 'transparent',
                    color: Colors.disabled,
                    fill: Colors.disabled,
                    borderColor: Colors.disabled,
                },
            },
        },
        // CTextButton has inherited the link variant. only use this variant is specifically instructed
        text: {
            h: 'none',
            p: 0,
            margin: 0,
            fontSize: 'inherit',
            minWidth: 0,
            whiteSpace: 'normal',
            textAlign: 'left',
            padding: 0,
            paddingInlineStart: 0,
            paddingInlineEnd: 0,
            border: 'none',
            display: 'inline',
            textDecoration: 'underline',
            verticalAlign: 'baseline',
            _hover: {
                textDecoration: 'none',
                _disabled: {
                    textDecoration: 'underline',
                },
            },
        },
        link: {
            h: 'none',
            border: 'none',
            margin: 0,
            minWidth: 0,
            whiteSpace: 'normal',
            textAlign: 'left',
            padding: 0,
            paddingInlineStart: 0,
            paddingInlineEnd: 0,
            color: theme.primary,
            fontSize: 'inherit',
            textDecoration: 'underline',
            _hover: {
                textDecoration: 'None',
            },
        },
        subtle: {
            padding: `${Spacing.XXS}`,
            margin: 0,
            bg: Colors.grayLight,
            _hover: {
                bg: theme.createStateColor(theme.grayLight),
            },
            h: 'none',
        },
        icon: {
            bg: 'none',
            color: theme.grayDark,
            _hover: {
                color: theme.createStateColor(theme.grayDark),
                bg: theme.lighten(theme.grayDark, 0.8),
            },
            borderRadius: BorderRadius.XL,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0,
            minWidth: 0,
            paddingInlineStart: 0,
            paddingInlineEnd: 0,
            height: '24px',
            width: '24px',
        },
        stacked: {
            display: 'block',
            textWrap: 'wrap',
            height: '100%',
            padding: Spacing.XS,
            margin: 0,
            bg: Colors.grayLight,
            _hover: {
                bg: theme.createStateColor(theme.grayLight),
            },
        },
    },
});

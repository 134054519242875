import React from 'react';
import { Badge, BadgeProps } from '@chakra-ui/react';
import {
    Colors,
    ColorSchemes,
    FontSizes,
    FontWeights,
    Spacing,
} from '../ChakraTheme';

export const CBadgeBase = ({ children, ...props }: BadgeProps): JSX.Element => (
    <Badge
        fontSize={FontSizes.sm}
        fontWeight={FontWeights.regular}
        py={Spacing.XXS}
        px={Spacing.XS}
        variant="outline"
        whiteSpace="break-spaces"
        {...props}
    >
        {children}
    </Badge>
);

export const CBadge = ({ children, ...props }: BadgeProps): JSX.Element => (
    <CBadgeBase colorScheme={ColorSchemes.gray} {...props}>
        {children}
    </CBadgeBase>
);

export const CBadgeTheme = ({
    children,
    ...props
}: BadgeProps): JSX.Element => (
    <CBadgeBase colorScheme={ColorSchemes.primary} {...props}>
        {children}
    </CBadgeBase>
);

export const CBadgeSuccess = ({
    children,
    ...props
}: BadgeProps): JSX.Element => (
    <CBadgeBase colorScheme="green" {...props}>
        {children}
    </CBadgeBase>
);

export const CBadgeError = ({
    children,
    ...props
}: BadgeProps): JSX.Element => (
    <CBadgeBase colorScheme="red" {...props}>
        {children}
    </CBadgeBase>
);

export const CBadgeWarning = ({
    children,
    ...props
}: BadgeProps): JSX.Element => (
    <CBadgeBase colorScheme="orange" {...props}>
        {children}
    </CBadgeBase>
);

export const CBetaBadge = ({
    ...props
}: Omit<BadgeProps, 'children'>): JSX.Element => (
    <CBadgeBase bg={Colors.primary} variant="solid" {...props}>
        Beta
    </CBadgeBase>
);

/* eslint-disable no-bitwise */
/**
 * use to generate consistent object of responsive  styles
 * @param smallValue
 * @param regularValue
 * @returns
 */
export const responsiveStyles = (
    smallValue: number | string,
    regularValue: number | string
): any => {
    return {
        base: smallValue,
        md: regularValue,
    };
};

export type RGBA = {
    r: number;
    g: number;
    b: number;
    a?: number;
};

/** Function by chatGPT
 * Generate an array of 10 tints of a given hex color.
 *
 * @param {string} hexColor - The input hex color code (e.g., "#3498db").
 * @returns {string[]} - An array of 10 lighter tints in hex format.
 */
export function generateTints(hexColor: string): { [key: number]: string } {
    // Helper function to convert hex to RGB
    function hexToRgb(hex: string) {
        const bigint = parseInt(hex.slice(1), 16);
        return {
            r: (bigint >> 16) & 255,
            g: (bigint >> 8) & 255,
            b: bigint & 255,
        };
    }

    // Helper function to convert RGB back to hex
    function rgbToHex({ r, g, b }: RGBA) {
        return `#${((1 << 24) + (r << 16) + (g << 8) + b)
            .toString(16)
            .slice(1)}`;
    }

    // Blend a color with white by a given factor (0 to 1)
    function blendWithWhite({ r, g, b }: RGBA, factor: number) {
        return {
            r: Math.round(r + (255 - r) * factor),
            g: Math.round(g + (255 - g) * factor),
            b: Math.round(b + (255 - b) * factor),
        };
    }
    function blendWithBlack({ r, g, b }: RGBA, factor: number) {
        return {
            r: Math.round(r * (1.5 - factor)),
            g: Math.round(g * (1.5 - factor)),
            b: Math.round(b * (1.5 - factor)),
        };
    }

    // Parse the input color
    const rgb = hexToRgb(hexColor);

    // Generate tints
    const tints: any = {};
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 10; i++) {
        switch (true) {
            case i === 0:
                tints[`50`] = rgbToHex(blendWithWhite(rgb, (10 - 0.5) / 10));
                break;
            case i < 5:
                tints[`${i}00`] = rgbToHex(blendWithWhite(rgb, (10 - i) / 10));
                break;
            case i === 5:
                tints[`${5}00`] = hexColor;
                break;
            default:
                tints[`${i}00`] = rgbToHex(
                    blendWithBlack(rgb, (10 - (10 - i)) / 10)
                );
        }
    }
    return tints;
}

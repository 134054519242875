/* eslint-disable no-unused-vars */
import { Box } from '@chakra-ui/react';
import {
    KoddiLocaleKey,
    KODDI_LOCALE_TO_DATEFNS,
} from 'app/koddi-components/src/LocaleProvider';
import React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { CFormError } from '../../../../Typography';
import { CustomDatePickerInput } from '../atoms';
import { BaseDatePickerProps } from '../DatePicker.types';
import { useSingleDatePicker } from './SingleDatePicker.hooks';
import { DatePickerStyled } from './SingleDatePicker.styled';

Object.keys(KODDI_LOCALE_TO_DATEFNS).forEach((localeKey) => {
    registerLocale(
        localeKey,
        KODDI_LOCALE_TO_DATEFNS[localeKey as KoddiLocaleKey]
    );
});

export const CSingleDatePicker = ({
    testId,
    name,
    errorText,
    hideError,
    inputValue,
    onChange,
    locale,
    disabled = false,
    onBlur,
    showIcon,
    ...props
}: BaseDatePickerProps): JSX.Element => {
    const {
        date,
        handleChangeRaw,
        handleChange,
        refCustomInput,
    } = useSingleDatePicker({ inputValue, onChange });

    return (
        <Box minWidth="150px" width="100%">
            <DatePickerStyled>
                <ReactDatePicker
                    locale={locale}
                    selected={date}
                    onChangeRaw={(e) => handleChangeRaw(e)}
                    onChange={(v: Date | null) => handleChange(v)}
                    popperPlacement="bottom-end"
                    customInput={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <CustomDatePickerInput
                            name={name}
                            hasError={!!errorText}
                            ref={refCustomInput}
                            disabled={disabled}
                            showIcon={showIcon}
                        />
                    }
                    closeOnScroll={(e) => e.target === document}
                    name={name}
                    data-test={`${testId}--date-picker`}
                    disabled={disabled}
                    onCalendarClose={onBlur}
                    {...props}
                />
                {!hideError && (
                    <CFormError
                        data-test={`${testId}--error`}
                        text={errorText}
                    />
                )}
            </DatePickerStyled>
        </Box>
    );
};

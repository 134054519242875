import { KoddiBudgetType, KoddiGoalType } from 'types';

export type CampaignBudgetType = Extract<
    'Weekly' | 'Daily' | 'Monthly' | 'Custom',
    KoddiBudgetType
>;

export type CampaignBudget = {
    /** The time period against which Budget is allocated to a given Campaign. */
    type: CampaignBudgetType;
    amount: number;
};

export type CampaignGoalType = Extract<'ROAS', KoddiGoalType>;

export type CampaignPrimaryGoal = {
    type: CampaignGoalType;
    value: string;
};

export type CampaignSecondaryGoal = {
    type: CampaignGoalType;
    value: string;
};

export type InsertionOrderObject = {
    insertion_order_id?: number;
    line_item_id: number;
    purchase_order_number: string;
};

export type CampaignStatus =
    | 'active'
    | 'pending'
    | 'inactive'
    | 'ended'
    | 'paused';

export enum CampaignReviewStatusID {
    UnderReview = 1,
    Denied = 2,
    Approved = 3,
}

export type CampaignReviewData = {
    campaign_review_id: number;
    campaign_review_status_id: CampaignReviewStatusID;
    comment: string;
    last_edited_by: number;
    last_edited_at: string;
} | null;

export type CampaignPacing = 'ASAP' | 'Evenly';

/** A Publisher-specific execution of a Media Strategy. */
export type CampaignBudgetInfo =
    | {
          base_bid: number;
          budget_end_date: string;
          budget_key: string;
          budget_start_date: string;
          budget_type: string;
          campaign_end_date: string;
          campaign_start_date: string;
          current_budget: number;
          current_pacing_budget: number;
          current_spend: number;
          initial_budget: number;
          is_active: boolean;
          is_paused: boolean;
          member_group_id: number;
          pacing_type: string;
          timezone: string;
      }
    | Record<string, unknown>;

export type Campaign = {
    id: number;
    campaign_id: number;
    media_strategy_id: number;
    advertiser_id: number;
    is_auto_bid?: boolean;
    name: string;
    campaign_name: string;
    start_date: string;
    end_date?: string | null;
    always_on: boolean;
    status: CampaignStatus;
    publisher_id: number;
    enable_frequency_capping: boolean;
    member_group_id: number;
    entity_id?: number;
    /**
     * Controls how many times a given user is shown an Ad from the Ad Group(s)
     * within a given Campaign within a given timeframe, i.e. `3 per Day`, `5 per Week`, `2 per 3 Days`.
     */
    frequency_capping: string;
    /**
     * Controls the pacing of the spend for a given Campaign and all Ad Groups within in.
     * Both of the options refer to a specific day,
     * i.e. spend `evenly` throughout a given day or spend as fast as possible within a given day.
     */
    pacing: CampaignPacing;
    /**
     * The dollar value that a user wants to spend for the given Budget Type
     * against a given Publisher within a Media Strategy.
     */
    budget: CampaignBudget;
    primary_goal: CampaignPrimaryGoal;
    secondary_goal: CampaignSecondaryGoal;
    experience_id?: number;
    insertion_order?: InsertionOrderObject;
    min_budget_value?: number;
    campaign_review?: CampaignReviewData;
};

export type PlayerCompetitorLeaderData = {
    player?: number;
    competitor?: number;
    leader?: number;
};

export type BulkEditCampaignStatus = 'Active' | 'Pending' | 'Paused' | 'Ended';

export type CampaignSpecificArgs = {
    memberGroupId: number;
    advertiserId: number;
    campaignId: number;
};

export type ActivatedCampaignDraftData = {
    campaign_id: number;
    ad_groups: number[];
};

export type CampaignReviewHistory = {
    review_status_id: CampaignReviewStatusID;
    comment: string;
    last_editing_user: {
        user_id: number;
        name: string;
    };
    last_edited_at: string;
};

export type CampaignHistory = {
    timestamp: string;
    email: string;
    start_date: string;
    end_date?: string;
    always_on: boolean;
    is_auto_bid: boolean;
    budget: number;
    budget_type: string;
    status_id: string;
    pacing: string;
};

export type DuplicateCampaignResponse = {
    id: number;
    advertiser_id: number;
    experience_id: number;
    name: string;
    start_date: string;
    end_date: string;
    status: string;
    always_on: boolean;
    is_auto_bid: boolean;
    enable_frequency_capping: boolean;
    frequency_capping: string;
    pacing: string;
    budget: {
        type: string;
        amount: number;
    };
    primary_goal: {
        type: string;
        value: number;
    };
    insertion_order: {
        line_item_id: number;
        purchase_order_number: string;
    };
    integrations: string[];
    hidden: boolean;
    metadata: Record<string, unknown>;
};

export enum BorderRadius {
    XS = '2px',
    SM = '4px',
    MD = '8px',
    LR = '16px',
    XL = '32px',
}

export enum BorderWidth {
    Thinnest = '1px',
    Thin = '2px',
    Thick = '6px',
}

export const Border = {
    Width: BorderWidth,
    Radius: BorderRadius,
};
